import api from "@/utils/api";
import { saveData, loadData, removeData } from "@/utils/storageHelper";
import { v4 as uuidv4 } from 'uuid';
import router from '@/router';


const getDefaultState = () => ({
  activeCount: 0,
  token: null,
  refreshToken: null,
  sessionType: null,
  user: null,
  locations: [],
  schedules: [],
  terminals: [],
  config: null,
  summary: null,
  selectedTab: null,
  summaryDetailed: null,
  orderTypes: [],
  activeLocationId: null,
  initialized: false,
  accessList: [],
  setupMode: {
    active: false,
    screen: null,
    payload: null
  },
  isLoggedIn: false,
  configVersion: null,
  loginMethod: null, // 'oauth', 'email', 'email-link'
});

// Debounce function
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default {
  namespaced: true,
  state: () => {
    const savedState = loadData('admin_session');
    if (savedState) {
      try {
        const { token, user, activeLocationId, accessList } = JSON.parse(savedState);
        return { ...getDefaultState(), token, user, activeLocationId, accessList };
      } catch (error) {
        console.error("Failed to parse saved state:", error);
        // Optionally, you can clear the invalid saved state
        removeData('admin_session');
      }
    }
    return getDefaultState();
  },
  getters: {
    initialized: state => state.initialized,
    isLoggedIn: state => !!state.token,
    isAdmin: state => state.user?.shift4_admin || false,
    userLocations: state => state.locations,
    currentConfig: state => state.config,
    orderTypes: state => state.orderTypes,
    activeLocation: state => state.activeLocation,
    activeLocationId: state => state.activeLocationId,
    terminals: state => state.terminals,
    summary: state => state.summary,
    summaryDetailed: state => state.summaryDetailed,
    locations: state => state.locations,
    schedules: state => state.schedules,
    accessList: state => state.accessList,
    isInSetupMode: state => state.setupMode.active,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_SELECTED_TAB(state, tab) {
      state.selectedTab = tab;
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    SET_SESSION_TYPE(state, sessionType) {
      state.sessionType = sessionType;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_INITIALIZED(state, initialized) {
      state.initialized = initialized;
    },
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
    SET_SCHEDULES(state, schedules) {
      state.schedules = schedules;
    },
    SET_TERMINALS(state, terminals) {
      state.terminals = terminals;
    },
    SET_ACTIVE_LOCATION_ID(state, locationId) {
      console.log("SET_ACTIVE_LOCATION_ID", locationId);
      state.activeLocationId = locationId;
    },
    SET_ACTIVE_LOCATION(state, location) {
      state.activeLocation = location;
    },
    SET_CONFIG(state, config) {
      state.config = config;
    },
    SET_ORDER_TYPES(state, orderTypes) {
      state.orderTypes = orderTypes;
    },
    SET_SUMMARY(state, summary) {
      state.summary = summary;
    },
    SET_SUMMARY_DETAILED(state, summaryDetailed) {
      state.summaryDetailed = summaryDetailed;
    },
    CLEAR_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    ADD_ORDER_TYPE(state, orderType) {
      state.config.orderTypes.push(orderType);
    },
    UPDATE_ORDER_TYPE(state, { index, orderType }) {
      if (index >= 0 && index < state.config.orderTypes.length) {
        state.config.orderTypes.splice(index, 1, orderType);
      }
    },
    DELETE_ORDER_TYPE(state, index) {
      if (index >= 0 && index < state.config.orderTypes.length) {
        state.config.orderTypes.splice(index, 1);
      }
    },
    SET_ACTIVE_COUNT(state, count) {
      state.activeCount = count;
    },
    SET_ACCESS_LIST(state, accessList) { // Add mutation for accessList
      state.accessList = accessList;
    },
    SET_SETUP_MODE(state, { screen, payload }) {
      state.setupMode = {
        active: payload?.active,
        screen,
        payload
      };
    },
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_CONFIG_VERSION(state, version) {
      state.configVersion = version;
    },
    SET_LOGIN_METHOD(state, method) {
      state.loginMethod = method;
    },
  },
  actions: {
    async initiateOAuth() {
      try {
        const response = await api.callAPI('get', '/oauth/redirect');
        window.location.href = response.url;
      } catch (error) {
        console.error('Failed to initiate OAuth flow:', error);
      }
    },
    setSelectedTab({ commit }, tab) {
      commit('SET_SELECTED_TAB', tab);
    },
    async handleOAuthCallback({ commit, dispatch }, code) {
      try {
        const response = await api.callAPI('get', `/oauth/callback?code=${code}`);
        const { token, user, accessList } = response;
        commit('SET_TOKEN', token);
        commit('SET_USER', user);
        commit('SET_ACCESS_LIST', accessList); // This is already set correctly
        console.log("Logged in as:", JSON.stringify(user));
        commit('SET_SESSION_TYPE', 'email');
        const activeLocationId = user.locations[0];
        commit('SET_ACTIVE_LOCATION_ID', activeLocationId);
        commit('SET_ACTIVE_LOCATION', user.locations.find(l => l.id === activeLocationId));
        saveData('admin_session', JSON.stringify({ token, user, activeLocationId, accessList })); // Add accessList here
        await dispatch('fetchInitialData');
        return true;

      } catch (error) {
        console.error('Failed to handle OAuth callback:', error);
      }
    },
    activeCount({ commit }, count) {
      commit('SET_ACTIVE_COUNT', count);
    },
    async generateSchedules({ state }, plainText) {
      if (!state.activeLocationId) {
        throw new Error("Active location ID is not set.");
      }
      console.log("Generating schedules from text:", plainText);
      const response = await api.callAPI('post', `/admin/schedulesFromText/${state.activeLocationId}`, { text: plainText }, state.token);
      return response || [];
    },
    async login({ commit, dispatch }, credentials) {
      try {
        const { token, user, accessList } = await api.callAPI('post', '/auth/login', credentials);
        commit('SET_TOKEN', token);
        commit('SET_USER', user);
        commit('SET_ACCESS_LIST', accessList); // This is already set correctly
        console.log("Logged in as:", JSON.stringify(user));
        commit('SET_SESSION_TYPE', 'email');
        const activeLocationId = user.locations[0];
        commit('SET_ACTIVE_LOCATION_ID', activeLocationId);
        commit('SET_ACTIVE_LOCATION', user.locations.find(l => l.id === activeLocationId));
        saveData('admin_session', JSON.stringify({ token, user, activeLocationId, accessList })); // Add accessList here
        await dispatch('fetchInitialData');
        commit('SET_LOGGED_IN', true);
        return true;
      } catch (error) {
        console.error("Login failed:", error);
        commit('SET_LOGGED_IN', false);
        return false;
      }
    },
    async register({ commit, dispatch }, credentials) {
      try {
        const { token, user, accessList } = await api.callAPI('post', '/auth/register', credentials);
        commit('SET_TOKEN', token);
        commit('SET_USER', user);
        commit('SET_ACCESS_LIST', accessList); // This is already set correctly
        console.log("Registered as:", JSON.stringify(user));
        commit('SET_SESSION_TYPE', 'email');
        const activeLocationId = user.locations[0];
        commit('SET_ACTIVE_LOCATION_ID', activeLocationId);
        commit('SET_ACTIVE_LOCATION', user.locations.find(l => l.id === activeLocationId));
        saveData('admin_session', JSON.stringify({ token, user, activeLocationId, accessList })); // Add accessList here
        await dispatch('fetchInitialData');
        return true;
      } catch (error) {
        console.error("Registration failed:", error);
        return false;
      }
    },
    async getLocations({ commit, state }) {
      if (state.token) {
        const locations = await api.callAPI('get', '/auth/user/locations', null, state.token);
        commit('SET_LOCATIONS', locations);
      }
    },
    async setActiveLocation({ commit, rootState, dispatch }, location) {
      console.log('setActiveLocation called with:', location);
      if (location?.id) {
        location = location.id;
      }
      commit('SET_ACTIVE_LOCATION_ID', location);
      console.log("Active location set to:", location);
      await dispatch('initWebSocketNow', { locationId: location, terminalId: rootState.terminal.id }, { root: true });
      await dispatch('fetchInitialData');
      console.log('Initial data fetched');
    },
    async fetchInitialData({ state, dispatch, commit }) {
      console.log("fetchInitialData", state.token, state.activeLocationId);
      if (state.token && state.activeLocationId) {
        try {
          await dispatch('fetchConfig');
          await dispatch('fetchLocations');
          await dispatch('fetchSchedules');
          await dispatch('fetchTerminals');
          await dispatch('fetchOrderTypes');
          commit('SET_ACTIVE_LOCATION', state.locations.find(l => l.id === state.activeLocationId));
          commit('SET_INITIALIZED', true);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            dispatch('handleUnauthorized', router.currentRoute.value.fullPath);
          } else {
            console.error("Failed to fetch initial data:", error);
          }
        }
      }
    },
    async fetchOrderTypes({ commit, state }) {
      console.log("state", state);
      if (state.config.rails === 'stm') {
        try {
          const orderTypes = await api.callAPI('get', `/admin/order-types/${state.activeLocationId}`, null, state.token);
          commit('SET_ORDER_TYPES', orderTypes?.orderTypes || []);
        } catch (err) {
          console.error("Failed to fetch order types:", err);
        }
      }
    },
    async fetchSummary({ commit, state }, { period = 'day' }) {
      if (state.selectedTab !== 'dashboard') {
        return;
      }

      if (state.token && state.activeLocationId) {
        const summary = await api.callAPI('get', `/admin/summary/${state.activeLocationId}?period=${period}`, null, state.token);
        const summaryDetailed = await api.callAPI('get', `/admin/summary-detailed/${state.activeLocationId}?period=${period}`, null, state.token);
        commit('SET_SUMMARY', summary);
        commit('SET_SUMMARY_DETAILED', summaryDetailed);
      }
    },
    async fetchTerminals({ commit, state }) {
      if (state.token && state.activeLocationId) {
        const terminals = await api.callAPI('get', `/admin/terminals/${state.activeLocationId}`, null, state.token);
        commit('SET_TERMINALS', terminals);
      }
    },
    async fetchLocations({ commit, state }) {
      console.log('Fetching locations');
      if (state.token) {
        try {
          const locations = await api.callAPI('get', '/auth/user/locations', null, state.token);
          console.log('Fetched locations:', locations);
          commit('SET_LOCATIONS', locations);
        } catch (error) {
          console.error('Error fetching locations:', error);
        }
      } else {
        console.error('No token available to fetch locations');
      }
    },
    async fetchSchedules({ commit, state }) {
      if (state.token && state.activeLocationId) {
        const schedules = await api.callAPI('get', `/admin/schedules/${state.activeLocationId}`, null, state.token);
        commit('SET_SCHEDULES', schedules);
      }
    },
    async fetchConfig({ commit, state }) {
      if (state.token && state.activeLocationId) {
        const config = await api.callAPI('get', `/admin/config/${state.activeLocationId}`, null, state.token);
        commit('SET_CONFIG', config);
        commit('SET_CONFIG_VERSION', config.version);
      }
    },
    logout({ commit }) {
      removeData('admin_session');
      commit('CLEAR_STATE');
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
      commit('SET_ACCESS_LIST', []);
      commit('SET_SESSION_TYPE', null);
      commit('SET_ACTIVE_LOCATION_ID', null);
      commit('SET_ACTIVE_LOCATION', null);
      commit('SET_ORDER_TYPES', []);
      commit('SET_LOGGED_IN', false);
    },
    async updateActiveLocation({ commit, state, dispatch }, location) {
      commit('SET_ACTIVE_LOCATION', location);
      await dispatch('fetchInitialData');
      saveData('admin_session', JSON.stringify({
        token: state.token,
        activeLocation: location
      }));
    },
    async saveConfig({ state, commit }, config) {
      try {
        const payload = { ...config, version: state.configVersion };
        const resp = await api.callAPI('post', `/admin/config/${state.activeLocationId}`, payload, state.token);
        commit('SET_CONFIG', resp);
        commit('SET_CONFIG_VERSION', resp.version);
        return true;
      } catch (error) {
        if (error.response?.status === 409) {
          alert("Configuration has been updated by another user. Please reload and try again.");
        } else {
          alert("Failed to save config: " + error.response?.data || error?.message || 'Unknown Error');
        }
        return false;
      }
    },
    async uploadLogo({ state }, formData) {
      formData.append('locationId', state.activeLocationId);
      const response = await api.callAPI('post', `/admin/upload-logo`, formData, state.token);
      return response;
    },
    async deleteLogo({ state, commit }, { url }) {
      await api.callAPI('post', `/admin/delete-logo`, { url }, state.token);
      commit('SET_CONFIG', { ...state.config, logo: { url: null } });
    },
    async updateOrderType({ commit, state, dispatch }, { index, orderType }) {
      if (!orderType.id) {
        orderType.id = uuidv4();
      }
      commit('UPDATE_ORDER_TYPE', { index, orderType });
      const resp = await dispatch('saveConfig', state.config);
      return resp;
    },
    async addOrderType({ commit, state, dispatch }, orderType) {
      if (state.token) {
        if (!orderType.id) {
          orderType.id = uuidv4();
        }
        commit('ADD_ORDER_TYPE', orderType);
        const resp = await dispatch('saveConfig', state.config);
        return resp;
      }
    },
    async deleteOrderType({ commit, state, dispatch }, { index }) {
      if (state.token) {
        const origState = JSON.parse(JSON.stringify(state.config));
        commit('DELETE_ORDER_TYPE', index);
        const resp = await dispatch('saveConfig', state.config);
        if (!resp) {
          commit('SET_CONFIG', origState);
        }
      }
    },
    async deleteTerminal({ state, dispatch }, terminalId) {
      if (state.token) {
        await api.callAPI('delete', `/admin/terminals/${state.activeLocationId}/${terminalId}`, null, state.token);
        dispatch('fetchTerminals');  // Fetch updated list
      }
    },
    async addTerminal({ state, dispatch }, terminalData) {
      const req = {
        locationId: state.activeLocationId,
        name: terminalData.name,
      };
      if (state.token) {
        await api.callAPI('post', `/admin/terminals`, req, state.token);
        dispatch('fetchTerminals');  // Fetch updated list
      }
    },
    async restartTerminals({ state }) {
      if (state.token && state.activeLocationId) {
        await api.callAPI('post', '/admin/restart-terminals', { locationId: state.activeLocationId }, state.token);
      }
    },
    updateConfigProperty: debounce(async function ({ state, commit }, { key, value }) {
      if (state.token && state.activeLocationId) {
        console.log("Updating config property:", key, value);
        try {
          const payload = {
            locationId: state.activeLocationId,
            key,
            value,
            version: state.configVersion
          };

          console.log("payload:", payload);
          const response = await api.callAPI('post', `/admin/config/property/${state.activeLocationId}`, payload, state.token);
          const updatedConfig = await api.callAPI('get', `/admin/config/${state.activeLocationId}`, null, state.token);
          commit('SET_CONFIG', updatedConfig);
          commit('SET_CONFIG_VERSION', updatedConfig.version);
          return response;
        } catch (error) {
          if (error.response?.status === 409) {
            alert("Configuration has been updated by another user. Please reload and try again.");
          } else {
            alert("Failed to update config property: " + error.response?.data || error?.message || 'Unknown Error');
          }
          return false;
        }
      }
    }, 1000),
    setSetupMode({ commit, dispatch }, { screen, payload }) {
      commit('SET_SETUP_MODE', { screen, payload });
      // If you need to send this information to the backend
      return dispatch('sendSetupModeToBackend', { screen, payload });
    },
    async sendSetupModeToBackend({ state }, { screen, payload }) {
      try {
        if (!state.activeLocation || !state.activeLocation.id) {
          console.error('No active location set');
          return;
        }
        await api.callAPI('post', `/admin/setup-mode/${state.activeLocation.id}`, {
          screen,
          payload
        }, state.token);
      } catch (error) {
        console.error('Failed to send setup mode to backend:', error);
      }
    },
    exitSetupMode({ commit }) {
      commit('SET_SETUP_MODE', { active: false, screen: null, data: null });
    },
    handleUnauthorized({ dispatch }, currentRoute) {
      // Clear the current session
      dispatch('logout');
      // Redirect to login
      router.push({ name: 'admin-login', query: { redirect: currentRoute } });
    },
    async uploadVideo({ state }, formData) {
      try {
        const response = await api.callAPI('post', `/admin/upload-video`, formData, state.token);
        return response;
      } catch (error) {
        console.error('Failed to upload video:', error);
        throw error;
      }
    },
    async deleteVideo({ state }, { url }) {
      try {
        await api.callAPI('post', `/admin/delete-video`, { url }, state.token);
        console.log('Video deleted successfully');
      } catch (error) {
        console.error('Failed to delete video:', error);
        throw error;
      }
    },
    async enhanceModifierNames({ state }) {
      if (state.token && state.activeLocationId) {
        try {
          await api.callAPI('post', `/admin/enhance-modifiers/${state.activeLocationId}`, null, state.token);
          // Refresh menu data after enhancement
          await api.callAPI('post', `/admin/sync/${state.activeLocationId}`, null, state.token);
          return true;
        } catch (error) {
          console.error('Failed to enhance modifier names:', error);
        }
      }
    },
    async updateTerminalConfig({ state }, { terminalId, config }) {
      if (state.token) {
        try {
          await api.callAPI('post', `/terminal/config/${terminalId}`, { config }, state.token);
          await this.dispatch('admin/fetchTerminals');
          return true;
        } catch (error) {
          console.error('Failed to update terminal config:', error);
          throw error;
        }
      }
    },
    /* eslint-disable */
    async generateLoginLink({ commit }, { email }) {
      try {
        const response = await api.callAPI('post', '/auth/generate-login-link', { email });
        return response;
      } catch (error) {
        console.error("Failed to generate login link:", error);
        throw error;
      }
    },
    async verifyLoginToken({ commit, dispatch }, { token }) {
      try {
        const { token: sessionToken, user, accessList } = await api.callAPI('post', '/auth/verify-login-token', { token });
        commit('SET_TOKEN', sessionToken);
        commit('SET_USER', user);
        commit('SET_ACCESS_LIST', accessList);
        commit('SET_LOGIN_METHOD', 'email-link');
        commit('SET_SESSION_TYPE', 'email');
        const activeLocationId = user.locations[0];
        commit('SET_ACTIVE_LOCATION_ID', activeLocationId);
        commit('SET_ACTIVE_LOCATION', user.locations.find(l => l.id === activeLocationId));
        saveData('admin_session', JSON.stringify({ token: sessionToken, user, activeLocationId, accessList }));
        await dispatch('fetchInitialData');
        commit('SET_LOGGED_IN', true);
        return true;
      } catch (error) {
        console.error("Login with token failed:", error);
        commit('SET_LOGGED_IN', false);
        throw error;
      }
    },
  }
};
