<template>
    <div class="items">
        <div class="top-bar">
            <div class="search">
                <SvgIcon name="search" class="search-icon" width="1.5rem" height="1.5rem" />
                <input v-model="searchQuery" type="text" class="search-bar" :class="{ 'search-active': isSearching }"
                    placeholder="Search" @focus="handleFocus" @blur="handleBlur" @input="updateSearchQuery" />
            </div>
            <div class="button-group" v-if="!isSearching">
                <button v-for="ot in orderTypes" :key="ot.type" class="btn"
                    :class="{ 'selected': selectedOrderType === ot, 'btn-secondary': selectedOrderType !== ot.type }"
                    @click="selectOrderType(ot)">
                    {{ ot.name }}
                </button>
            </div>
            <div class="button-group" v-else>
                <button @mousedown="handleCancel">Cancel</button>
            </div>
        </div>
        <div v-if="isSearching">
            <div class="item-list">
                <div v-for="item in filteredItems" :key="item.ref"
                    :class="['item', { 'no-image': !item.images.length }]" @click="itemSelected(item)">
                    <div class="item-content">
                        <div v-if="item.images.length"
                            :style="{ backgroundImage: 'url(' + imageUrl(item.images[0]) + ')' }" class="item-image-bg">
                        </div>
                        <div v-else class="color-block">
                            <span class="item-name-block">{{ item.name }}</span>
                        </div>
                        <span class="item-name">{{ item.name }}</span>
                        <span class="item-price">{{ formatCurrency(item.price) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <transition v-else name="fade" mode="out-in">
            <div class="item-list" v-if="localSelectedDepartment" :key="localSelectedDepartment.ref">
                <div v-for="item in departmentItems(localSelectedDepartment)" :key="item.ref"
                    :class="['item', { 'no-image': !item.images.length }]" @click="itemSelected(item)">
                    <div class="item-content">
                        <div v-if="item.images.length"
                            :style="{ backgroundImage: 'url(' + imageUrl(item.images[0]) + ')' }" class="item-image-bg">
                        </div>
                        <div v-else class="color-block">
                            <span class="item-name-block">{{ item.name }}</span>
                        </div>
                        <span class="item-name">{{ item.name }}</span>
                        <span class="item-price">{{ formatCurrency(item.price) }}</span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { imageUrl, formatCurrency } from "@/utils/helpers";
import SvgIcon from '@/components/global/SvgIcon.vue';

export default {
    components: {
        SvgIcon
    },
    data() {
        return {
            isSearching: false,
            searchQuery: '',
            filteredItems: null,
            localSelectedDepartment: this.selectedDepartment
        }
    },
    props: {
        selectedDepartment: {
            type: Object,
            required: false
        },
        items: {
            type: Array,
            required: true
        },
        itemsToSearch: {
            type: Array,
            required: false
        },
        departmentItems: {
            type: Function,
            required: true
        },
        orderTypes: {
            type: Array,
            required: true,
        },
        selectedOrderType: {
            type: Object,
            required: true
        },
    },
    mounted() {
        this.filteredItems = this.itemsToSearch || this.items;
        this.filteredItems = this.filteredItems.sort((a, b) => a.name.localeCompare(b.name));
    },
    watch: {
        selectedDepartment(newVal) {
            this.localSelectedDepartment = newVal;
        }
    },
    methods: {
        imageUrl,
        formatCurrency,
        selectOrderType(type) {
            this.$router.push({ name: 'start', query: { orderTypeRef: type.id } });
        },
        itemSelected(item) {
            this.$emit('item-selected', item);
        },
        highlightedName(name) {
            if (!this.searchQuery) return name;
            const regex = new RegExp(`(${this.searchQuery})`, 'gi');
            return name.replace(regex, '<strong style="color: #9baec8">$1</strong>');
        },
        truncatedName(name) {
            return name.length > 10 ? name.substring(0, 10) + '...' : name;
        },
        handleCancel() {
            this.searchQuery = null;
            this.isSearching = false;
            this.$emit('setBarcodeActive', true);
        },
        handleFocus() {
            this.isSearching = true;
            this.$emit('setBarcodeActive', false);
        },
        handleBlur() {
            setTimeout(() => {
                this.isSearching = false;
                this.searchQuery = null;
                this.$emit('setBarcodeActive', true);
            }, 200);

        },
        updateSearchQuery(event) {
            this.searchQuery = event.target.value;
            this.searchItems();
        },
        searchItems() {
            this.filteredItems = this.itemsToSearch
                .filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
                .sort((a, b) => a.name.localeCompare(b.name));
        }
    }
};
</script>
<style scoped>
.top-bar {
    display: flex;
    align-items: center;
    padding: 2.125rem 1.375rem 1rem 1.375rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.9);
}

.search {
    display: flex;
    align-items: center;
    position: relative; /* Ensure the container is positioned relatively */
}

.search-icon {
    position: absolute;
    left: 1rem; /* Adjust as needed for spacing */
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
    margin: 0; /* Remove any additional margins */
}

.search-bar {
    flex-grow: 1;
    width: 100%; /* Use full width */
    padding-left: 3rem; /* Add padding to the left to accommodate the icon */
    border: 0.125rem solid #2A2927;
    font-size: 1.75rem !important;
    margin: 0;
    line-height: 1.25rem;
    padding: 1rem 2rem 1rem 3rem; /* Adjust padding */
    color: rgba(255, 255, 255, 0.64);
    background: #000;
    font-weight: 400;
    border-radius: 6.25rem;
    margin-right: 2rem;
    transition: width 0.3s ease, border-color 0.3s ease;
    /* Add transition for width */
}


.search-bar:focus {
    outline: none;
    border: 0.125rem solid #fff !important;
    color: #fff;
}

.search-bar.search-active {
    width: 30rem;
    /* Adjust as needed */
}

.button-group {
    border: 0.125rem solid #2A2927;
    border-radius: 1rem;
    padding: 0.25rem;
}

.button-group button {
    font-size: 1.5rem;
    border: none;
    font-weight: 700;
    border-radius: 0.75rem;
    line-height: 1.75rem;
    color: #fff;
    background-color: #000;
    padding: 1.125rem 1.625rem;
}

.button-group button.selected {
    background: #424659;
}

.item-list {
    margin: 1.25rem;
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
    gap: 1rem;
}

.item-list h2 {
    font-size: 2rem;
}

.item-list .item {
    cursor: pointer;
    border: 1px solid #34383c;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
    height: 250px;
    border-radius: 1rem;
}

.item-list .item:hover {
    background-color: #2a2c30;
    transform: translateY(-5px);
}

.item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.item-image-bg {
    width: 100%;
    height: 150px;
    /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
}

.color-block {
    background-color: #2b2c2e;
    /* Replace with desired color */
    display: flex;
    width: 100%;
    height: 150px;
    /* Same height as the image for consistency */
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
}

.color-block .item-name-block {
    font-size: 1.375rem;
    line-height: 1.5rem;
    width: 10rem;
    text-overflow: ellipsis;
    font-weight: 700;
    text-align: left;
    margin: 2rem;
    margin: auto;
}

.item.no-image {
    text-align: left;
}

.item.no-image .item-image {
    display: none;

}

.item-list .item .item-name {
    font-weight: bold;
    padding: 0rem 1rem 0.5rem 1rem;
    height: auto;
    /* Allow height to adjust based on content */
    word-break: break-word;
    color: #e6e8eb;
    text-align: left;
    /* Left align text */
    white-space: normal;
    /* Allow text to wrap */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.item-list .item .item-price {
    color: #9baec8;
    font-weight: 700;
    padding: 0 1rem;
    margin-top: auto;
    /* Push price to the bottom */
    text-align: right;
    /* Align price to the right */
}

.item-list .item .item-name strong {
    color: #9baec8 !important;
}


.color-block {
    background-color: #2b2c2e;
    /* Replace with desired color */
    display: flex;
    width: 100%;
    height: 150px;
    /* Same height as the image for consistency */
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
}

.color-block .item-name-block {
    font-size: 1.375rem;
    line-height: 1.5rem;
    width: 10rem;
    text-overflow: ellipsis;
    font-weight: 700;
    text-align: left;
    margin: 2rem;
    margin: auto;
}

</style>
