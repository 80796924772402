<!-- components/LoginComponent.vue -->
<template>
  <div class="d-flex align-items-center justify-content-center vh-100 main">
    <div class="login-container p-4 shadow-sm rounded-3">
      <button @click="initiateOAuth" class="btn btn-primary btn-lg w-100 mb-4 d-flex justify-content-center">
        <SvgIcon name="four" class="icon" width="1rem" height="1rem" /> 
        <span>Login with Shift4</span>
      </button>
      
      <!-- Only show these links if support mode is active -->
      <div v-if="supportMode" class="d-flex justify-content-between">
        <a href="#" @click.prevent="toggleEmailLogin" class="small-link">Login with Email/Password</a>
        <a href="#" @click.prevent="toggleLoginLink" class="small-link">Login with Link</a>
      </div>
      
      <form v-if="showEmailLogin" @submit.prevent="login" class="mt-4">
        <div class="form-floating mb-3">
          <input type="email" class="form-control" id="email" placeholder="Email" v-model="email" required ref="emailInput">
          <label for="email">Email</label>
        </div>
        <div class="form-floating mb-3">
          <input type="password" class="form-control" id="password" placeholder="Password" v-model="password" required>
          <label for="password">Password</label>
        </div>
        <button type="submit" class="btn btn-primary btn-lg w-100">Login</button>
      </form>
      
      <form v-if="showLoginLink" @submit.prevent="requestLoginLink" class="mt-4">
        <div class="form-floating mb-3">
          <input type="email" class="form-control" id="linkEmail" placeholder="Email" v-model="linkEmail" required ref="linkEmailInput">
          <label for="linkEmail">Email</label>
        </div>
        <button type="submit" class="btn btn-primary btn-lg w-100">Send Login Link</button>
        <div v-if="loginLinkSent" class="alert alert-success mt-3">
          Login link has been sent to your email.
        </div>
      </form>
      
      <p v-if="error" class="error mt-3 text-center">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SvgIcon from '@/components/global/SvgIcon.vue';

export default {
  components: {
    SvgIcon
  },
  props: {
    token: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      email: '',
      password: '',
      linkEmail: '',
      error: '',
      showEmailLogin: false,
      showLoginLink: false,
      loginLinkSent: false,
      supportMode: false,
      typedText: '',
      supportKeyword: 'support'
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    
    // Check for support mode parameter
    this.supportMode = urlParams.has('support');
    
    if (urlParams.has('triggerOAuth')) {
      this.initiateOAuth();
    }
    
    // Check if we have a token in the URL or props
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl || this.token) {
      this.verifyLoginToken(tokenFromUrl || this.token);
    }

    // Listen for keydown events to detect if user types "support"
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    // Clean up event listener
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    ...mapActions("admin", ['initiateOAuth', 'login', 'generateLoginLink']),
    handleKeyDown(event) {
      // Only track alphabetical keys
      if (/^[a-zA-Z]$/.test(event.key)) {
        this.typedText += event.key.toLowerCase();
        
        // Keep only the last N characters where N is the length of the support keyword
        if (this.typedText.length > this.supportKeyword.length) {
          this.typedText = this.typedText.substring(this.typedText.length - this.supportKeyword.length);
        }
        
        // Check if the typed text matches the support keyword
        if (this.typedText === this.supportKeyword) {
          this.supportMode = true;
          this.typedText = ''; // Reset typed text
        }
      }
    },
    toggleEmailLogin() {
      this.showEmailLogin = !this.showEmailLogin;
      this.showLoginLink = false;
      if (this.showEmailLogin) {
        this.$nextTick(() => {
          this.$refs.emailInput.focus();
        });
      }
    },
    toggleLoginLink() {
      this.showLoginLink = !this.showLoginLink;
      this.showEmailLogin = false;
      if (this.showLoginLink) {
        this.$nextTick(() => {
          this.$refs.linkEmailInput.focus();
        });
      }
    },
    async login() {
      try {
        const credentials = { email: this.email, password: this.password };
        await this.$store.dispatch('admin/login', credentials);

        if (this.$store.getters['admin/isLoggedIn']) {
          const redirectPath = this.$route.query.redirect || '/admin';
          this.$router.replace(redirectPath);
        } else {
          this.error = 'Invalid email or password';
        }
      } catch (error) {
        this.error = 'An error occurred during login';
      }
    },
    async requestLoginLink() {
      try {
        await this.$store.dispatch('admin/generateLoginLink', { email: this.linkEmail });
        this.loginLinkSent = true;
        this.error = '';
      } catch (error) {
        this.error = error.response?.data?.error || 'Failed to send login link';
        this.loginLinkSent = false;
      }
    },
    async verifyLoginToken(token) {
      try {
        await this.$store.dispatch('admin/verifyLoginToken', { token });
        
        if (this.$store.getters['admin/isLoggedIn']) {
          const redirectPath = this.$route.query.redirect || '/admin';
          this.$router.replace(redirectPath);
        } else {
          this.error = 'Invalid or expired login link';
        }
      } catch (error) {
        this.error = error.response?.data?.error || 'Invalid or expired login link';
      }
    }
  }
};
</script>

<style scoped>
.main {
  font-size: 1rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.login-container {
  background-color: #fff;
  max-width: 28rem;
  width: 100%;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.error {
  color: var(--status-error);
  font-size: 1.25rem;
  font-weight: 600;
}

.small-link {
  display: block;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.small-link:hover {
  text-decoration: none;
  color: var(--primary-dark);
}

.icon {
  margin-right: 0.75rem;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  background-color: var(--secondary-dark);
  border-color: var(--secondary-dark);
}

.form-floating label {
  color: #6c757d;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--primary);
}

input::placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: #6c757d;
}
</style>